*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
}

.react-modal-overlay {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: none;
  z-index: 20;
  display: flex;
}

/* .react-modal-wrapper {
    max-width: 700px;
    width: 100%;
    margin: 16px auto;
} */

.react-modal-content {
  background-color: #fbfbfb;
  background-clip: padding-box;
  position: relative;
  border-radius: 8px;
  align-self: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.react-modal-header,
.react-modal-body,
.react-modal-footer {
  padding: 16px;
}

.react-modal-header {
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.react-modal-title {
  font-size: 18px;
  line-height: 1.5;
}

.btn-close {
  border: none;
  font-size: 16px;
  padding: 4px;
  cursor: pointer;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
}

.react-modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #dedede;
  margin: 0.8rem;
}

.react-modal-footer > * {
  margin-left: 0 4px;
}
