body,
html {
  margin: 0;
  box-sizing: border-box;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.loader {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
}

.loader:after {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loader 2s linear infinite;
}

/* #my-tooltipV {
  font-size: 9px;
  font-weight: bold;
  background: #000;
  opacity: 0.5;
  display: none;
} */

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.darkLoader {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
}

.darkLoader:after {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: darkLoader 2s linear infinite;
}

@keyframes darkLoader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button {
  display: block;
  animation: 200ms linear 50ms btnAnimation;
}

@keyframes btnAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.97);
  }

  100% {
    transform: scale(1);
  }
}

.content {
  top: 64px !important;
  width: calc(100vw - 220px);
  height: calc(100vh - 64px);
  position: relative;
  left: 220px;
}

.contentSkeleton {
  top: 64px !important;
  width: calc(100vw - 300px);
  height: calc(100vh - 64px);
  position: relative;
  left: 300px;
}

.dashContent {
  top: 64px !important;
  width: calc(100vw - 220px);
  height: calc(100vh - 64px);
  position: relative;
  left: 220px;
}

.Adscontent {
  top: 64px;
  width: calc(100vw - 220px) !important;

  /* height: max-content; */
  position: relative;
  left: 220px;
  padding: 2rem;
}

.topbar {
  width: calc(100vw - 220px);
  position: fixed;
  left: 220px;
}

.topbarSkeleton {
  width: calc(100vw - 300px);
  position: fixed;
  left: 300px;
}
.activeLink {
  background: black;
  width: 100% !important;
  height: fit-content !important;
}
/* .activeLink:first-child {
 
} */

.activeLink > div {
  width: 100% !important;
  background-color: #fff;
}
.activeLink > div > h1 {
  color: black !important;
  width: fit-content !important;
}

.icon-important {
  width: 20px !important;
}
.activeLink > div > .icon {
  color: black !important;
  width: fit-content !important;
}

.hoverLink {
  background: black;
  width: 100% !important;
  height: fit-content !important;
}
.hoverLink:hover {
  height: fit-content !important;
}
.hoverLink:hover > div {
  background-color: #fff;
  transition: all 0.5s ease-in;
}
.hoverLink:hover > div > h1 {
  color: black !important;
  width: fit-content !important;
}
.hoverLink:hover > div > .icon {
  color: black !important;
  width: fit-content !important;
}

#scrollToTop {
  transition: 0.3s;
}

#scrollToTop.active {
  opacity: 1;
  visibility: visible;
  z-index: 10;
}

@layer components {
  .my-react-select-container .my-react-select__control {
    @apply border border-primary pl-2 bg-white rounded outline-none font-sans text-xs w-full;
  }
  .my-react-select-container .my-react-select__control--is-focused {
    @apply shadow-none;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-white  border-neutral-300;
  }

  .my-react-select-container .my-react-select__option {
    @apply text-[#000] bg-white hover:bg-neutral-200 hover:text-primary;
  }

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-neutral-400;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-[#000];
  }
  .my-react-select-container .my-react-select__placeholder {
    @apply text-[#9ca3af];
    @apply bg-white;
  }
}

.css-1q89gsl-control {
  background-color: #fff !important;
}
.css-bl2mj7-control {
  background-color: #fff !important;
}

.my-react-select__menu {
  /* background-color: blue !important; */
  color: black !important;
}
.css-gpf4is-multiValue {
  border-radius: 5px !important;
}
.css-1pvvjro-control {
  background-color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.css-hlj6pa-MuiDialogActions-root,
.css-z3au5x-MuiButtonBase-root-MuiIconButton-root-MuiPickersToolbar-penIconButton,
.css-1hbyad5-MuiTypography-root {
  display: none !important;
}
.css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
  background-color: black !important;
  color: white !important;
}
.Mui-selected {
  background-color: #bdbdbd !important;
}
.css-1vcokmn-MuiCalendarOrClockPicker-root {
  border: 1px solid black !important;
  border-radius: 4px;
}
.MuiPickersDay-hiddenDaySpacingFiller + span {
  display: none !important;
}
/*fixed height for the registration stepper*/
.fixedHeight {
  height: calc(100vh - 40px);
}

/* the slides */
.slick-slide > div {
  margin: 0 0px;
  padding: 0 10px;
}

.slick-list {
  margin: 0 -7px;
}

.slick-prev:before,
.slick-next:before {
  color: #000000 !important;
}
.prevArrow::before {
  font-size: 30px !important;
  padding-right: 20px !important;
}

.nextArrow::before {
  font-size: 30px !important;
  /* padding-left: 0px !important; */
}

.scroll-modal::-webkit-scrollbar {
  display: none;
}

.sidebar {
  animation: sidebarAnimation 0.5s ease-in-out forwards;
}

@keyframes sidebarAnimation {
  0% {
    transform: translateX(-220px);
  }

  100% {
    transform: translateX(220px);
  }
}

.hideSideBar {
  animation: sidebarRmAnimation 0.5s ease-in-out forwards;
}

@keyframes sidebarRmAnimation {
  0% {
    transform: translateX(220px);
  }

  100% {
    transform: translateX(-220px);
  }
}

/* start of general responsiveness */

@media screen and (max-width: 976px) {
  .content {
    top: 64px !important;
    width: 100%;
    height: calc(100vh - 64px);
    left: 0;
  }
  .topbar {
    width: 100%;
    position: fixed;
    left: 0;
  }
}

/*end of general responsiveness */

.select-container {
  height: 50px; /* set initial height */
  transition: height 0.3s ease-in-out; /* add transition effect */
}

.select-container .select__control--is-focused {
  height: auto; /* expand height when focused */
}

.css-b62m3t-container {
  margin-bottom: 0;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  appearance: textfield;
}
.itemsHeight {
  height: calc(100vh - 244px) !important;
}

#checkbox {
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 5%;
  opacity: 50%;
}
#checkbox:checked {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 38px !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.selected-flag {
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}

progress {
  height: 20px !important;
  width: 100%;
}

progress[value] {
  --color: black !important; /* the progress color */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
}
progress[value]::-webkit-progress-bar {
  border-radius: 10px;
}
progress[value]::-webkit-progress-value {
  background: black !important;
  border-radius: 10px;
}

.animateList {
  position: relative;
  min-height: 0px;
  height: 0px;
  overflow: hidden;
  transition: height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.open {
  height: 100%;
}
